<template>
	<article class="app-download-for-ios">
		<div class="download-ios-header">
			<div  class="download-ios-header__inner">
				<p class="download-ios-header__title">スゴ得契約者ならお得に 頭痛ーるアプリの全ての機能使い放題!</p>
			</div>
		</div>

		<div class="download-ios-procedure">
			<h2 class="download-ios-procedure__title">プレミアム機能の開始方法</h2>
			<p class="download-ios-procedure__text">①アプリをダウンロード<br>②スゴ得でログイン<span class="download-ios-procedure__usage" @click="onScroll('js_usage')">詳しい手順はこちら</span></p>
		</div>

		<div class="download-ios-here">
			<p class="download-ios-here__text is-short-margin">＼いますぐアプリをダウンロード！／</p>
			<div class="download-ios-here__inner">
				<div class="download-ios-here__hiroshi"><i class="sprite sprite-appdownload_ios_character01" /></div>
				<div class="download-ios-here__button"><a href="https://apps.apple.com/jp/app/id602991338" class="sprite sprite-appdownload_button01" id="trackingAppStoreBtn01"></a></div>
				<div class="download-ios-here__maro"><i class="sprite sprite-appdownload_ios_character02" /></div>
			</div>
		</div>

		<!-- NOTE: グロース案件適用のためコメントアウト -->
		<!-- <section class="download-ios-possible">
			<h2 class="download-ios-possible__title"><div class="download-ios-possible__title--icon"><i class="sprite sprite-appdownload_ios_icon01" /></div>通常版アプリとはここが違う！<br><span class="download-ios-possible__title--emphasis">プレミアム機能でできること</span></h2>
			<ul class="download-ios-possible__list">
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>過去の記録もさかのぼって確認！<br><span class="download-ios-possible__text--emphasis">痛み記録期間が無制限に</span></p>
					</div>
					<div class="download-ios-possible__img--large"><i class="sprite sprite-appdownload_ios_icon03" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>他のみんなの体調がわかる！<br><span class="download-ios-possible__text--emphasis">みんなの痛みナウ</span></p>
					</div>
					<div class="download-ios-possible__img--xlarge"><i class="sprite sprite-appdownload_ios_icon04" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>6日後までの予報が見られる！<br><span class="download-ios-possible__text--emphasis">予報日数増加</span></p>
					</div>
					<div class="download-ios-possible__img--large"><i class="sprite sprite-appdownload_ios_icon05" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>さらに細かい地点で予報を確認！<br><span class="download-ios-possible__text--emphasis">都道府県ピンポイント予報</span></p>
					</div>
					<div class="download-ios-possible__img"><i class="sprite sprite-appdownload_ios_icon06" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>記録が一覧になって見やすい！<br><span class="download-ios-possible__text--emphasis">痛みカレンダー</span></p>
					</div>
					<div class="download-ios-possible__img"><i class="sprite sprite-appdownload_ios_icon07" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>痛み回数や薬服用の傾向がわかる！<br><span class="download-ios-possible__text--emphasis">月別レポート</span></p>
					</div>
					<div class="download-ios-possible__img"><i class="sprite sprite-appdownload_ios_icon08" /></div>
				</li>
			</ul>
		</section>

		<div class="download-ios-allow">
			<div class="download-ios-allow__balloon">
				<p>その他にも…<br><span class="download-ios-allow__balloon--emphasis">・広告の非表示<br>・プッシュ通知の時間と地点を詳細設定</span><br>といった便利機能を使用することが可能になるんじゃ☆</p>
			</div>
			<div class="download-ios-allow__character"><i class="sprite sprite-appdownload_ios_character03" /></div>
		</div> -->

		<!-- ↓グロース案件 -->
		<section class="growth-download-ios-possible">
			<div class="growth-download-ios-possible__intro">
				<p class="growth-download-ios-possible__intro--text">
					<span>頭痛ーるアプリの素敵なところを紹介するニャ</span>
				</p>
				<i class="growth-download-ios-possible__intro--img" />
			</div>
			<div class="growth-download-ios-possible__box">
				<h2 class="growth-download-ios-possible__box--title">痛み＆くすり記録</h2>
				<div class="growth-download-ios-possible__box--inner">
					<div class="growth-download-ios-possible__box--img is-type01">
						<img src="@/assets/img/growthhack/pain_record.png" alt="痛み記録画像">
					</div>
					<p class="growth-download-ios-possible__box--explanatory">頭痛の頻度、何の薬を飲んだかなどを記録することができます。</p>
				</div>
			</div>
			<div class="growth-download-ios-possible__box">
				<h2 class="growth-download-ios-possible__box--title">薬名記録</h2>
				<div class="growth-download-ios-possible__box--inner">
					<div class="growth-download-ios-possible__box--img is-type02">
						<img src="@/assets/img/growthhack/drug_name_record_img.png" alt="薬名記録画像">
					</div>
					<p class="growth-download-ios-possible__box--explanatory">市販薬から処方薬まで対応しています。薬の名前は入力しなくても、薬名記録から選ぶだけ。<br>履歴が残るので、2回目からはタップするだけです。</p>
				</div>
			</div>
			<div class="growth-download-ios-possible__box">
				<h2 class="growth-download-ios-possible__box--title">頭痛×気圧タイプ診断</h2>
				<div class="growth-download-ios-possible__box--inner">
					<div class="growth-download-ios-possible__box--img is-type02">
						<img src="@/assets/img/growthhack/record_10_times_img.png" alt="頭痛×気圧タイプ診断画像">
					</div>
					<p class="growth-download-ios-possible__box--explanatory">痛みを10回記録すると、どのタイミングで頭痛が起こるタイプかわかります。</p>
				</div>
			</div>
			<div class="growth-download-ios-possible__box is-after-none">
				<h2 class="growth-download-ios-possible__box--title">気圧アラート通知</h2>
				<div class="growth-download-ios-possible__box--inner">
					<div class="growth-download-ios-possible__box--img is-type02">
						<img src="@/assets/img/growthhack/pain_alert_image.png" alt="気圧アラート通知画像">
					</div>
					<p class="growth-download-ios-possible__box--explanatory">翌日に気圧が大きく低下すると予想される場合にプッシュ通知でお知らせします。</p>
				</div>
				<div class="about-balloon is-margin-20">
					<div class="about-balloon__img is-type04"><i class="about-balloon__bg sprite sprite-about_balloon_img02" /></div>
					<div class="about-balloon__box">
						<div class="about-balloon__talk"><i class="about-balloon__bg sprite sprite-about_balloon_bg02" /></div>
						<p class="about-balloon__text is-type02">事前に薬を飲むなどの対策ができるのじゃ！</p>
					</div>
				</div>
				<div class="growth-download-ios-possible__box--appstore">
					<img src="@/assets/img/growthhack/app_store.png" alt="App Store">
					<p>プレミアムサービスを利用していただいた方の<span>95%が2ヶ月以上</span>継続して下さっています。<br><span>（2020年4月〜2020年7月に購入開始いただいた方々の継続率平均）</span></p>
				</div>
			</div>
		</section>
		<!-- ↑グロース案件 -->

		<div class="download-ios-here">
			<p class="download-ios-here__text">＼いますぐアプリをダウンロード！／</p>
			<div class="download-ios-here__inner">
				<div class="download-ios-here__hiroshi"><i class="sprite sprite-appdownload_ios_character01" /></div>
				<div class="download-ios-here__button"><a href="https://apps.apple.com/jp/app/id602991338" class="sprite sprite-appdownload_button01" id="trackingAppStoreBtn02"></a></div>
				<div class="download-ios-here__maro"><i class="sprite sprite-appdownload_ios_character02" /></div>
			</div>
		</div>

		<section class="download-ios-usage" id="js_usage">
			<h2 class="download-ios-usage__title">アプリでのスゴ得ログイン方法</h2>
			<div class="download-ios-usage__box">
				<div class="download-ios-usage__box--left">
					<p class="download-ios-usage__box--img">
						<i class="sprite sprite-appdownload_ios_step01" />
					</p>
					<span class="download-ios-usage__box--text">iosアプリを開き、<br>気圧グラフが表示されるまで進む。</span>
				</div>
				<p class="download-ios-usage__box--right">
					<img src="@/assets/img/appdownload_ios_img01.png" alt="Step1イメージ">
				</p>
			</div>
			<div class="download-ios-usage__box">
				<div class="download-ios-usage__box--left">
					<p class="download-ios-usage__box--img">
						<i class="sprite sprite-appdownload_ios_step02" />
					</p>
					<span class="download-ios-usage__box--text">左上の「三」マークをタップする。</span>
				</div>
				<p class="download-ios-usage__box--right">
					<img src="@/assets/img/appdownload_ios_img02.png" alt="Step2イメージ">
				</p>
			</div>
			<div class="download-ios-usage__box">
				<div class="download-ios-usage__box--left">
					<p class="download-ios-usage__box--img">
						<i class="sprite sprite-appdownload_ios_step03" />
					</p>
					<span class="download-ios-usage__box--text">「スゴ得コンテンツご契約の方」を選択して案内通りに進む。</span>
				</div>
				<p class="download-ios-usage__box--right">
					<img src="@/assets/img/appdownload_ios_img03.png" alt="Step3イメージ">
				</p>
			</div>
			<p class="download-ios-usage__caution">すでにプレミアム会員（有料会員）の方は、プレミアム会員（有料会員）解約後、ご利用可能です。<br>その場合、当月の会員費はかかりますのでご注意ください。</p>
		</section>

		<div class="download-ios-here">
			<p class="download-ios-here__text">＼いますぐアプリをダウンロード！／</p>
			<div class="download-ios-here__inner">
				<div class="download-ios-here__hiroshi"><i class="sprite sprite-appdownload_ios_character01" /></div>
				<div class="download-ios-here__button"><a href="https://apps.apple.com/jp/app/id602991338" class="sprite sprite-appdownload_button01" id="trackingAppStoreBtn03"></a></div>
				<div class="download-ios-here__maro"><i class="sprite sprite-appdownload_ios_character02" /></div>
			</div>
		</div>

		<!-- ↓グロース案件 -->
		<section class="download-ios-possible">
			<h2 class="download-ios-possible__title"><div class="download-ios-possible__title--icon"><i class="sprite sprite-appdownload_ios_icon01" /></div>通常版アプリとはここが違う！<br><span class="download-ios-possible__title--emphasis">プレミアム機能でできること</span></h2>
			<ul class="download-ios-possible__list">
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>過去の記録もさかのぼって確認！<br><span class="download-ios-possible__text--emphasis">痛み記録期間が無制限に</span></p>
					</div>
					<div class="download-ios-possible__img--large"><i class="sprite sprite-appdownload_ios_icon03" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>他のみんなの体調がわかる！<br><span class="download-ios-possible__text--emphasis">みんなの痛みナウ</span></p>
					</div>
					<div class="download-ios-possible__img--xlarge"><i class="sprite sprite-appdownload_ios_icon04" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>6日後までの予報が見られる！<br><span class="download-ios-possible__text--emphasis">予報日数増加</span></p>
					</div>
					<div class="download-ios-possible__img--large"><i class="sprite sprite-appdownload_ios_icon05" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>さらに細かい地点で予報を確認！<br><span class="download-ios-possible__text--emphasis">都道府県ピンポイント予報</span></p>
					</div>
					<div class="download-ios-possible__img"><i class="sprite sprite-appdownload_ios_icon06" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>記録が一覧になって見やすい！<br><span class="download-ios-possible__text--emphasis">痛みカレンダー</span></p>
					</div>
					<div class="download-ios-possible__img"><i class="sprite sprite-appdownload_ios_icon07" /></div>
				</li>
				<li class="download-ios-possible__item">
					<div class="download-ios-possible__text">
						<div class="download-ios-possible__text--icon"><i class="sprite sprite-appdownload_ios_icon02" /></div>
						<p>痛み回数や薬服用の傾向がわかる！<br><span class="download-ios-possible__text--emphasis">月別レポート</span></p>
					</div>
					<div class="download-ios-possible__img"><i class="sprite sprite-appdownload_ios_icon08" /></div>
				</li>
			</ul>
		</section>

		<div class="download-ios-allow">
			<div class="download-ios-allow__balloon">
				<p>その他にも…<br><span class="download-ios-allow__balloon--emphasis">・広告の非表示<br>・プッシュ通知の時間と地点を詳細設定</span><br>といった便利機能を使用することが可能になるんじゃ☆</p>
			</div>
			<div class="download-ios-allow__character"><i class="sprite sprite-appdownload_ios_character03" /></div>
		</div>

		<div class="download-ios-here">
			<p class="download-ios-here__text">＼いますぐアプリをダウンロード！／</p>
			<div class="download-ios-here__inner">
				<div class="download-ios-here__hiroshi"><i class="sprite sprite-appdownload_ios_character01" /></div>
				<div class="download-ios-here__button"><a href="https://apps.apple.com/jp/app/id602991338" class="sprite sprite-appdownload_button01" id="trackingAppStoreBtn03"></a></div>
				<div class="download-ios-here__maro"><i class="sprite sprite-appdownload_ios_character02" /></div>
			</div>
		</div>
		<!-- ↑グロース案件 -->

		<div class="download-ios-login about-rightnow__login">
			<slot />
		</div>
	</article>
</template>

<script>
export default {
	name: 'AppDownloadForIos',
	components: {
	},
	data () {
		return {
		}
	},
	computed: {
	},
	mounted () {
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.contentHeight()
	},
	beforeDestroy () {
	},
	methods: {
		// コンテンツの高さをemitする処理（実処理は親で行う）
		// NOTE: 現在、未使用
		contentHeight () {
			this.$nextTick(() => {
				this.$emit('contentHeight')
			})
		},

		/**
		 * ページスクロール用の関数
		 */
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

// NOTE: アプリダウンロードページiOS限定のスタイル（デザインシステム外のため他での利用禁止）
$download-ios-color-red: #CB0034;
$download-ios-possible-title: #C0A955;
$download-ios-possible-item: #D0B35B;
$download-ios-allow-text: #A99242;
$download-ios-allow-border: #CCC;
$download-ios-allow-background: #EEE;
$download-ios-usage-title: #0FA298;

$download-ios-spacing-25: 1.562rem;
$download-ios-spacing-30: 1.875rem;
$download-ios-spacing-40: 2.5rem;

$download-ios-font-size-19: 1.1875rem;
$download-ios-font-size-17: 1.0625rem;
$download-ios-font-size-15: 0.9375rem;
$download-ios-line-height-1_2: 1.2;

.app-download-for-ios {
	.sprite {
		background-image: url(~@/assets/img/sprite_appdownload_ios.png);
		background-repeat: no-repeat;
		display: block;
		&.sprite-appdownload_button01 {
			width: 327px;
			height: 120px;
			background-position: 0 0;
		}

		&.sprite-appdownload_ios_character01 {
			width: 106px;
			height: 126px;
			background-position: 0 -120px;
		}

		&.sprite-appdownload_ios_character02 {
			width: 133px;
			height: 93px;
			background-position: 0 -246px;
		}

		&.sprite-appdownload_ios_character03 {
			width: 330px;
			height: 220px;
			background-position: 0 -339px;
		}

		&.sprite-appdownload_ios_icon01 {
			width: 127px;
			height: 102px;
			background-position: 0 -559px;
		}

		&.sprite-appdownload_ios_icon02 {
			width: 100px;
			height: 80px;
			background-position: 0 -661px;
		}

		&.sprite-appdownload_ios_icon03 {
			width: 182px;
			height: 160px;
			background-position: 0 -741px;
		}

		&.sprite-appdownload_ios_icon04 {
			width: 247px;
			height: 169px;
			background-position: 0 -901px;
		}

		&.sprite-appdownload_ios_icon05 {
			width: 182px;
			height: 165px;
			background-position: 0 -1070px;
		}

		&.sprite-appdownload_ios_icon06 {
			width: 174px;
			height: 170px;
			background-position: 0 -1235px;
		}

		&.sprite-appdownload_ios_icon07 {
			width: 174px;
			height: 160px;
			background-position: 0 -1405px;
		}

		&.sprite-appdownload_ios_icon08 {
			width: 174px;
			height: 147px;
			background-position: 0 -1565px;
		}

		&.sprite-appdownload_ios_step01 {
			width: 200px;
			height: 200px;
			background-position: 0 -1712px;
		}

		&.sprite-appdownload_ios_step02 {
			width: 200px;
			height: 200px;
			background-position: 0 -1912px;
		}

		&.sprite-appdownload_ios_step03 {
			width: 200px;
			height: 200px;
			background-position: 0 -2112px;
		}
	}
}

.download-ios {
	&-header {
		&__inner {
			width: 100%;
			background: $about-header-background url(~@/assets/img/appdownload_ios_header_img01.png) no-repeat 0 0;
			background-size: 100% auto;
			position: relative;
			height: 0;
			padding-top: 30.67%;
		}
		&__title {
			width: 36%;
			height: 0;
			padding-top: 18%; // NOTE: タイトルの縦横比率
			position: absolute;
			top: 10%;
			left: 10%;
			overflow: hidden;
			color: transparent;
		}
	}
	&-procedure {
		margin: $spacing-20 $spacing-10 0;
		padding: $spacing-6 $download-ios-spacing-25 $spacing-10;
		border: 2px solid $download-ios-color-red;
		border-radius: 8px;
		&__title {
			font-size: $download-ios-font-size-17;
			margin: 0;
			text-align: center;
			&:before,
			&:after {
				content: '▼';
				color: $download-ios-color-red;
				font-size: $download-ios-font-size-19;
			}
		}
		&__text {
			font-size: $download-ios-font-size-15;
			margin: $spacing-2 0 0;
		}
		&__usage {
			color: $download-ios-color-red;
			margin-left: $spacing-6;
			text-decoration: underline;
		}
	}
	&-here {
		&__text {
			color: $download-ios-color-red;
			font-size: $font-size-20;
			font-weight: bold;
			margin: $download-ios-spacing-30 0 $spacing-6;
			text-align: center;
			&.is-short-margin {
				margin: $spacing-20 0 $spacing-6;
			}
		}
		&__inner {
			background: $background-secondary;
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding: $spacing-10;
		}
		&__hiroshi {
			position: relative;
			transform: scale(0.5);
			& > i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
		&__button {
			position: relative;
			padding: 18.35% $download-ios-spacing-30 0;
			transform: scale(0.5);
			& > a {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
		&__maro {
			position: relative;
			transform: scale(0.5);
			& > i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}
	&-possible {
		&__title {
			display: flex;
			justify-content: space-around;
			align-items: center;
			color: $text-seconday;
			font-size: $font-size-16;
			font-weight: normal;
			background: $download-ios-possible-title;
			margin-top: $spacing-20;
			padding: $spacing-16 0;
			text-align: center;
			&--icon {
				position: relative;
				margin-right: -#{$download-ios-spacing-40};
				transform: scale(0.5);
				& > i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
				}
			}
			&--emphasis {
				display: contents;
				font-size: $font-size-20;
				font-weight: bold;
			}
		}
		&__list {
			margin: $download-ios-spacing-40 0 $spacing-20;
			padding: 0 $spacing-20;
		}
		&__item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 2px solid $download-ios-possible-item;
			padding: $spacing-16 0;
			&:last-child {
				border-bottom: 2px solid $download-ios-possible-item;
			}
		}
		&__text {
			&--icon {
				position: relative;
				display: inline-block;
				width: 50px;
				height: 40px;
				transform: scale(0.5);
				& > i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
				}
			}
			&--emphasis {
				font-size: $font-size-18;
				font-weight: bold;
			}
			& > p {
				margin: 0;
				line-height: $download-ios-line-height-1_2;
			}
		}
		&__img {
			$parent: &;
			position: relative;
			transform: scale(0.5);
			width: 87px;
			&--large {
				@extend #{$parent};
				width: 91px;
			}
			&--xlarge {
				@extend #{$parent};
				width: 123px;
			}
			& > i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}
	&-allow {
		&__balloon {
			position: relative;
			width: 84.266%;
			margin: 0 auto;
			border: 2px solid $download-ios-allow-border;
			background: $download-ios-allow-background;
			border-radius: 20px;
			&--emphasis {
				color: $download-ios-allow-text;
				font-weight: bold;
			}
			& > p {
				margin: 0;
				padding: 4.8% 4.8% 7.5%;
			}
			&::before {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translate(-50%);
				display: inline-block;
				border-right: 27px solid $download-ios-allow-border;
				border-bottom: 27px solid transparent;
			}
			&::after {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translate(-50%);
				display: inline-block;
				border-right: 23px solid $download-ios-allow-background;
				border-bottom: 23px solid transparent;
			}
		}
		&__character {
			position: relative;
			text-align: right;
			margin-top: -6%;
			margin-left: 54%;
			height: 110px;
			transform: scale(0.5);
			& > i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}
	&-usage {
		margin-top: 2.5rem;
		padding: 0 0.625rem;
		&__title {
			margin: 0;
			color: $download-ios-usage-title;
			font-size: 1rem;
			font-weight: bold;
			&::after {
				content: '';
				display: block;
				height: 2px;
				margin-top: 0.125rem;
				background-color: $download-ios-usage-title;
			}
		}
		&__box {
			display: flex;
			padding: 1.25rem 0 1.125rem;
			border-bottom: #ccc 1px solid;
			&:first-of-type {
				padding: 1.875rem 0 1.125rem;
			}
			&--left {
				position: relative;
				width: 50%;
				margin: 0 auto;
			}
			&--img {
				width: 100px;
				height: 100px;
				margin: 0 auto;
				i {
					transform: scale(0.5) translate(-100px, -100px);
				}
			}
			&--text {
				display: block;
				margin: 0.625rem auto 0;
				font-size: $download-ios-font-size-15;
				font-weight: bold;
				padding: 0 $spacing-10;
			}
			&--right {
				width: 50%;
				margin: 0 auto;
				img {
					width: 100%;
				}
			}
		}
		&__caution {
			font-size: $download-ios-font-size-15;
			margin: $spacing-20 0 0;
		}
	}
	&-login {
		margin-top: $download-ios-spacing-40;
	}
}

// NOTE: グロース案件
.growth-download-ios-possible {
	&__intro {
		&--text {
			position: relative;
			background: url(~@/assets/img/growthhack/about_balloon_bg06.png) no-repeat 0 0;
			background-size: 100% auto;
			margin: $spacing-20 auto 0;
			margin: $spacing-20 3.75rem 0 2.5625rem;
			padding-top: 27%;
			span {
				position: absolute;
				display: block;
				top: 37%;
				left: 0;
				width: 100%;
				box-sizing: border-box;
				padding: 0 0.937rem 0 0.937rem;
				transform: translateY(-50%);
				font-size: 0.937rem;
				@media (max-width: (360px)) { font-size: $font-size-12; }
			}
		}
		&--img{
			max-width: 120px;
			background: url(~@/assets/img/growthhack/maro_image.png) no-repeat 0 0;
			background-size: 100% auto;
			padding-top: 20%;
			display: block;
			margin: 0 auto;
		}
	}
	&__box {
		width: 100%;
		margin: 1.875rem auto 0;
		&--title {
			text-align: center;
			font-size: 1.375rem;
			line-height: 1.35;
			&::after {
				content: '';
				display: block;
				width: 100px;
				height: 6px;
				margin: $spacing-10 auto 0;
				background: $primary;
				border-radius: 6px;
			}
		}
		&--inner {
			margin-top: $spacing-20;
		}
		&--img {
			width: 100%;
		}
		&--explanatory {
			font-size: 0.937rem;
			margin: $spacing-10 $spacing-10 0;
		}
		&::after {
			content: '';
			display: block;
			width: 85%;
			height: 1px;
			margin: $spacing-20 auto 0;
			background: $line-primary;
		}
		&--appstore {
			margin-top: 2.5rem;
			img {
				max-width: 196px;
				width: 100%;
				display: block;
				margin: 0 auto;
			}
			p {
				margin-top: 1.125rem;
				padding: 0 0.5625rem;
				color: #333;
				span:nth-child(1) {
					color: #FF4D4D;
					font-size: 0.9375rem;
					font-weight: bold;
				}
				span:nth-child(2) {
					font-size: 0.8125rem;
				}
			}
		}
	}
	.is-after-none::after { content: none; }
	.sprite {
		background-image: url(~@/assets/img/sprite_appdownload.png);
		background-repeat: no-repeat;
		display: block;

		&.sprite-about_balloon_bg02 {
			width: 580px;
			height: 159px;
			background-position: 0 -160px;
		}

		&.sprite-about_balloon_img02 {
			width: 122px;
			height: 146px;
			background-position: 0 -431px;
		}
	}
	.is-type01 {
		width: 100%;
		padding: 0 1.1875rem 0 1.125rem;
		display: table-cell;
		img {
			width: 100%;
			display: block;
			margin: 0 auto;
		}
	}
	.is-type02 {
		width: 100%;
		img {
			width: 100%;
			max-width: 200px;
			display: block;
			margin: 0 auto;
		}
	}
	.is-margin-20 {
		margin-top: $spacing-20;
	}
}
</style>
