<template>
<div class="app-download" id="js_app-download_container">
	<div class="app-download-container">
		<app-download-for-android v-if="isAndroid" :debug-date="debugDate" @contentHeight="contentHeightController()" />
		<app-download-for-ios v-else @contentHeight="contentHeightController()">
			<span class="about-rightnow__login--button" @click="$router.push({ name: 'Cpsite', query: backPageLink })">{{ backPageText }}</span>
		</app-download-for-ios>
	</div>
</div>
</template>

<script>

// Vuex
import { mapGetters } from 'vuex'

// Compornents
import AppDownloadForAndroid from '@/components/Organisms/AppDownloadForAndroid'
import AppDownloadForIos from '@/components/Organisms/AppDownloadForIos'
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'AppDownload',
	components: {
		AppDownloadForAndroid,
		AppDownloadForIos
	},
	data () {
		return {
			backPageLink: { url: `${cmnConst.BACKEND_URL}?_path=weatherGraph` },
			backPageText: '気圧グラフにもどる'
		}
	},
	props: {
		backPage: {
			type: String,
			default: 'weatherGraph'
		},
		debugDate: {
			type: String,
			default: ''
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		if (this.backPage === 'top') {
			this.backPageText = 'TOPにもどる'
			this.backPageLink = { id: cmnConst.SUGOTOKU_CONTENTS_ID }
		}
	},
	beforeDestroy () {
	},
	methods: {
		// コンテンツの高さをemitする処理
		// NOTE: 現在、コンテンツの高さを確保する処理の見直しのため子コンポーネントからemitしないようにしている。未使用だが機能は残しておく。
		contentHeightController () {
			this.$nextTick(() => {
				// TODO: 表示が崩れるため、一旦「setTimeout」で対応
				setTimeout(() => {
					// TODO: 他に良い方法がみつかったらリファクタリング。エミットのエミットケースが増えるようならそのケースのルール決めが必要
					const domContainer = document.getElementById('js_app-download_container')
					if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
				}, 1000)
			})
		}
	}
}
</script>
